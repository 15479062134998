import React, { useRef, useState } from 'react'
import axios from 'axios'
import './contactUs.css'

import contactUsImg from '../assets/images/contactUs-side.png'

const TEMPLATE_ID = 'template_fnhc8jq'
const SERVICE_ID = 'service_bc2espv'
const PUBLIC_KEY = 'ABwN9vk3z2Ae_gYl2'

export default function ContactUs() {
    const form = useRef()

    const [isLoading, setLoading] = useState(false)

    const sendEmail = async (e) => {
        e.preventDefault()
        setLoading(true)

        const emailData = {
            service_id: SERVICE_ID,
            template_id: TEMPLATE_ID,
            user_id: PUBLIC_KEY,
            template_params: {
                name: e.target.name.value,
                email: e.target.email.value,
                number: e.target.number.value,
                subject: e.target.subject.value,
                message: e.target.message.value
            }
        }
        console.log(emailData)

        try {
            await axios.post('https://api.emailjs.com/api/v1.0/email/send', emailData)
            alert('Thank you for reaching out! We’ve received your message and will get back to you shortly.')
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (<div id='contact-us'>
        <div className='container'>
            <div className='title-wrapper'>
                <h2 className='title'>Contact Us</h2>
                <h4 className='sub-title'>Keep In Touch</h4>
            </div>
            <div className='contact-us-wrapper'>
                <div className='form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className='inputGroup'>
                            <input type='text' autoComplete='off' id='name' required disabled={isLoading} />
                            <label htmlFor='name'>Enter Your Name</label>
                        </div>
                        <div className='inputGroup'>
                            <input type='text' autoComplete='off' id='email' required disabled={isLoading} />
                            <label htmlFor='email'>Enter Your Email</label>
                        </div>
                        <div className='inputGroup'>
                            <input type='text' autoComplete='off' id='number' required disabled={isLoading} />
                            <label htmlFor='number'>Enter Your Phone Number</label>
                        </div>
                        <div className='inputGroup'>
                            <input type='text' autoComplete='off' id='subject' required disabled={isLoading} />
                            <label htmlFor='subject'>Enter Your Subject</label>
                        </div>
                        <div className='inputGroup'>
                            <textarea id='message' required disabled={isLoading} />
                            <label htmlFor='message'>Enter Your Message</label>
                        </div>
                        <button disabled={isLoading}>SUBMIT</button>
                    </form>
                </div>
                <div className='side-image'>
                    <img src={contactUsImg} alt='vimal about-us' />
                </div>
            </div>
        </div>
    </div>)
}
