import React, { useEffect, useState } from 'react'
import './ourGroup.css'

import { arrowRight } from '../assets/svgImages'
import aboutUsImg from '../assets/images/our_group/vimal-about.jpg'
import ImgClient1 from '../assets/images/our_group/Deep/client1.jpg'
import ImgClient2 from '../assets/images/our_group/Deep/client2.jpg'
import ImgClient3 from '../assets/images/our_group/Deep/client3.jpg'

export default function OurGroupDeep() {
    const startDate = new Date('2017', 0)
    const endDate = new Date()
    const yearOfExperience = endDate.getFullYear() - startDate.getFullYear()

    const [currentIndex, setCurrentIndex] = useState(0)
    const visibleItems = (window.innerWidth < '480') ? 3 : (window.innerWidth < '768') ? 4 : 5
    const pageCount = Math.ceil(clientList.length / visibleItems)

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide()
        }, 3000)
        return () => clearInterval(interval)
    }, [currentIndex])

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Math.ceil(clientList.length / visibleItems) - 1 ? 0 : prevIndex + 1
        )
    }

    const goToSlide = (index) => {
        setCurrentIndex(index)
    }

    return (<div className='ourGroup'>
        <div className='ourGroup-heroSection'>
            <div className='blur-back-dark'>
                <div className='container'>
                    <div className='ourGroup-hero-wrapper'>
                        <h3 className='h1'>Deep Infra</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className='about-company-section'>
            <div className='container'>
                <div className='about-company-wrapper'>
                    <div className='image-wrapper'>
                        <img src={aboutUsImg} alt='vimal about-us' />
                    </div>
                    <div className='detail'>
                        <h2 className='h2'>Vision</h2>
                        <p>
                            Welcome to Deep Infra, where we pave the way to excellence in road construction.
                            With a steadfast commitment to quality, safety, and innovation, we have established ourselves as a trusted leader in the industry.
                        </p>
                        <p>
                            Our journey began 2009, and since then, we have been dedicated to building and maintaining roads that connect communities, support economic growth, and enhance everyday life
                        </p>
                        <button style={{ display: 'none' }}> MORE <span dangerouslySetInnerHTML={{ __html: arrowRight }} /></button>
                    </div>
                </div>
            </div>
        </div>
        <div className='counts-section'>
            <div className='container'>
                <div className='counts-wrapper'>
                    {/* <div className='counts'>
                        <p className='number'>250+</p>
                        <p className='label'>Total Projects</p>
                    </div> */}
                    <div className='counts'>
                        <p className='number'>{yearOfExperience}+</p>
                        <p className='label'>Years Experience</p>
                    </div>
                    <div className='counts'>
                        <p className='number'>100+</p>
                        <p className='label'>Total Employees</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='clients-section'>
            <div className='container'>
                <div className='clients-wrapper'>
                    <div className='title-wrapper'>
                        <h4 className='sub-title'> Clients</h4>
                    </div>
                    <div className='client-list'>
                        <div className="carousel">
                            {clientList.slice(currentIndex * visibleItems, currentIndex * visibleItems + visibleItems).map((element, index) => {
                                const { image, title } = element
                                return (<img src={image} alt={title} title={title} key={index} />)
                            })}
                        </div>
                        <div className="pagination">
                            {pageCount > 1 && Array.from({ length: pageCount }).map((_, index) => (
                                <span
                                    key={index}
                                    className={index === currentIndex ? 'dot dot-active' : 'dot'}
                                    onClick={() => goToSlide(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

const clientList = [
    {
        image: ImgClient1,
        title: 'Amdavad Municipal Corporation'
    },
    {
        image: ImgClient2,
        title: 'Ahmedabad Urban Development Authority'
    },
    {
        image: ImgClient3,
        title: 'ESSEL Group'
    }
]
