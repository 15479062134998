import React, { useEffect, useState } from 'react'
import './ourGroup.css'

import { arrowRight } from '../assets/svgImages'
import aboutUsImg from '../assets/images/our_group/vimal-about.jpg'
import heroSectionImg from '../assets/images/our_group/hero_banner_vd.jpeg'

import ImgClient1 from '../assets/images/our_group/VD/client1.png'
import ImgClient2 from '../assets/images/our_group/VD/client2.png'
import ImgClient3 from '../assets/images/our_group/VD/client3.png'
import ImgClient4 from '../assets/images/our_group/VD/client4.png'
import ImgClient5 from '../assets/images/our_group/VD/client5.png'
import ImgClient6 from '../assets/images/our_group/VD/client6.jpg'
import ImgClient7 from '../assets/images/our_group/VD/client7.png'
import ImgClient8 from '../assets/images/our_group/VD/client8.jpg'
import ImgClient9 from '../assets/images/our_group/VD/client9.png'
import ImgClient10 from '../assets/images/our_group/VD/client10.png'
import ImgClient11 from '../assets/images/our_group/VD/client11.png'
import ImgClient12 from '../assets/images/our_group/VD/client12.png'
import ImgClient13 from '../assets/images/our_group/VD/client13.png'
import ImgClient14 from '../assets/images/our_group/VD/client14.png'
import ImgClient15 from '../assets/images/our_group/VD/client15.jpg'

export default function OurGroupVD() {
    const startDate = new Date('2022', 0)
    const endDate = new Date()
    const yearOfExperience = endDate.getFullYear() - startDate.getFullYear()

    const [currentIndex, setCurrentIndex] = useState(0)
    const visibleItems = (window.innerWidth < '480') ? 3 : (window.innerWidth < '768') ? 4 : 5
    const pageCount = Math.ceil(clientList.length / visibleItems)

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide()
        }, 3000)
        return () => clearInterval(interval)
    }, [currentIndex])

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Math.ceil(clientList.length / visibleItems) - 1 ? 0 : prevIndex + 1
        )
    }

    const goToSlide = (index) => {
        setCurrentIndex(index)
    }

    return (<div className='ourGroup'>
        <div className='ourGroup-heroSection' style={{ backgroundImage: `url(${heroSectionImg})` }}>
            <div className='blur-back-dark'>
                <div className='container'>
                    <div className='ourGroup-hero-wrapper'>
                        <h3 className='h1'>VD Zincare</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className='about-company-section'>
            <div className='container'>
                <div className='about-company-wrapper'>
                    <div className='image-wrapper'>
                        <img src={aboutUsImg} alt='vimal about-us' />
                    </div>
                    <div className='detail'>
                        <h2 className='h2'>Vision</h2>
                        <p>
                            To be a pioneering force in the field of zinc-based corrosion protection, revolutionizing the industry with innovative zinc coating solutions on steel.
                            We envision a world where steel structures are fortified with superior corrosion resistance, ensuring durability, safety, and environmental sustainability.
                            Through our relentless pursuit of excellence, we aim to establish <b>VD Zinacare</b> as the trusted partner for businesses seeking cutting-edge zinc coating technology.
                        </p>
                        <button style={{ display: 'none' }}> MORE <span dangerouslySetInnerHTML={{ __html: arrowRight }} /></button>
                    </div>
                </div>
            </div>
        </div>
        <div className='counts-section'>
            <div className='container'>
                <div className='counts-wrapper'>
                    {/* <div className='counts'>
                        <p className='number'>250+</p>
                        <p className='label'>Total Projects</p>
                    </div> */}
                    <div className='counts'>
                        <p className='number'>{yearOfExperience}+</p>
                        <p className='label'>Years Experience</p>
                    </div>
                    <div className='counts'>
                        <p className='number'>50+</p>
                        <p className='label'>Total Employees</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='clients-section'>
            <div className='container'>
                <div className='clients-wrapper'>
                    <div className='title-wrapper'>
                        <h4 className='sub-title'> Clients</h4>
                    </div>
                    <div className='client-list'>
                        <div className="carousel">
                            {clientList.slice(currentIndex * visibleItems, currentIndex * visibleItems + visibleItems).map((element, index) => {
                                const { image, title } = element
                                return (<img src={image} alt={title} title={title} key={index} />)
                            })}
                        </div>
                        <div className="pagination">
                            {pageCount > 1 && Array.from({ length: pageCount }).map((_, index) => (
                                <span
                                    key={index}
                                    className={index === currentIndex ? 'dot dot-active' : 'dot'}
                                    onClick={() => goToSlide(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

const clientList = [
    {
        image: ImgClient1,
        title: 'Dudhat Infra Products limited'
    },
    {
        image: ImgClient2,
        title: 'Bajani Hydraulics'
    },
    {
        image: ImgClient3,
        title: 'Karnavati Groups'
    },
    {
        image: ImgClient4,
        title: 'Vedanta transforming for good'
    },
    {
        image: ImgClient5,
        title: 'Suzlon'
    },
    {
        image: ImgClient6,
        title: 'Zetwerk'
    },
    {
        image: ImgClient7,
        title: 'Swaraj Secutech'
    },
    {
        image: ImgClient8,
        title: 'Western Railway'
    },
    {
        image: ImgClient9,
        title: 'Shri Avirat'
    },
    {
        image: ImgClient10,
        title: 'Allmarc'
    },
    {
        image: ImgClient11,
        title: 'Radadia Enginnering'
    },
    {
        image: ImgClient12,
        title: 'F.S. Engineers'
    },
    {
        image: ImgClient13,
        title: 'Larsen & Toubro'
    },
    {
        image: ImgClient14,
        title: 'Plast World'
    },
    {
        image: ImgClient15,
        title: 'Adani Renewables'
    }
]
