import React, { useEffect, useState } from 'react'
import './ourGroup.css'

import { arrowRight } from '../assets/svgImages'
import aboutUsImg from '../assets/images/our_group/vimal-about.jpg'
import ImgClient1 from '../assets/images/our_group/Vimal/client1.jpg'
import ImgClient2 from '../assets/images/our_group/Vimal/client2.JPG'
import ImgClient3 from '../assets/images/our_group/Vimal/client3.jpg'
import ImgClient4 from '../assets/images/our_group/Vimal/client4.JPG'
import ImgClient5 from '../assets/images/our_group/Vimal/client5.png'
import ImgClient6 from '../assets/images/our_group/Vimal/client6.JPG'
import ImgClient7 from '../assets/images/our_group/Vimal/client7.jpg'
import ImgClient8 from '../assets/images/our_group/Vimal/client8.png'
import ImgClient9 from '../assets/images/our_group/Vimal/client9.jpg'

export default function OurGroupVimal() {
    const startDate = new Date('2009', 0)
    const endDate = new Date()
    const yearOfExperience = endDate.getFullYear() - startDate.getFullYear()

    const [currentIndex, setCurrentIndex] = useState(0)
    const visibleItems = (window.innerWidth < '480') ? 3 : (window.innerWidth < '768') ? 4 : 5
    const pageCount = Math.ceil(clientList.length / visibleItems)

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide()
        }, 3000)
        return () => clearInterval(interval)
    }, [currentIndex])

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === Math.ceil(clientList.length / visibleItems) - 1 ? 0 : prevIndex + 1
        )
    }

    const goToSlide = (index) => {
        setCurrentIndex(index)
    }

    return (<div className='ourGroup'>
        <div className='ourGroup-heroSection'>
            <div className='blur-back-dark'>
                <div className='container'>
                    <div className='ourGroup-hero-wrapper'>
                        <h3 className='h1'>Vimal Infracon LLP</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className='about-company-section'>
            <div className='container'>
                <div className='about-company-wrapper'>
                    <div className='image-wrapper'>
                        <img src={aboutUsImg} alt='vimal about-us' />
                    </div>
                    <div className='detail'>
                        <h2 className='h2'>Vision</h2>
                        <p>
                            Our mission is to procure projects at competitive pricing provide safe working conditions and deliver quality work within reasonable time frame.
                            It is our mission to provide the best of work in our field irrespective of the challenges we face. We will not stop to dream because of difficult problems.
                        </p>
                        <p>
                            To be a respectable Roads/Highway and Building construction company delivering beyond expectation with a firm commitment of caring quality & environment, always.
                        </p>
                        <button style={{ display: 'none' }}> MORE <span dangerouslySetInnerHTML={{ __html: arrowRight }} /></button>
                    </div>
                </div>
            </div>
        </div>
        <div className='counts-section'>
            <div className='container'>
                <div className='counts-wrapper'>
                    <div className='counts'>
                        <p className='number'>250+</p>
                        <p className='label'>Total Projects</p>
                    </div>
                    <div className='counts'>
                        <p className='number'>{yearOfExperience}+</p>
                        <p className='label'>Years Experience</p>
                    </div>
                    <div className='counts'>
                        <p className='number'>150+</p>
                        <p className='label'>Total Employees</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='clients-section'>
            <div className='container'>
                <div className='clients-wrapper'>
                    <div className='title-wrapper'>
                        <h4 className='sub-title'> Clients</h4>
                    </div>
                    <div className='client-list'>
                        <div className="carousel">
                            {clientList.slice(currentIndex * visibleItems, currentIndex * visibleItems + visibleItems).map((element, index) => {
                                const { image, title } = element
                                return (<img src={image} alt={title} title={title} key={index} />)
                            })}
                        </div>
                        <div className="pagination">
                            {pageCount > 1 && Array.from({ length: pageCount }).map((_, index) => (
                                <span
                                    key={index}
                                    className={index === currentIndex ? 'dot dot-active' : 'dot'}
                                    onClick={() => goToSlide(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

const clientList = [
    {
        image: ImgClient1,
        title: 'Gujrat'
    },
    {
        image: ImgClient2,
        title: 'Ahmedabad Urban Development Authority'
    },
    {
        image: ImgClient3,
        title: 'Amdavad Municipal Corporation'
    },
    {
        image: ImgClient4,
        title: 'MORTH'
    },
    {
        image: ImgClient5,
        title: 'NHAI'
    },
    {
        image: ImgClient6,
        title: 'Gujarat Metro Rail Corporation Limited'
    },
    {
        image: ImgClient7,
        title: 'Government of Gujarat'
    },
    {
        image: ImgClient8,
        title: 'Essel Group '
    },
    {
        image: ImgClient9,
        title: 'Ahmedabad Urban Development Authority'
    }
]
