import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import HomePage from './pages/Home'
// import UnderConstruction from './pages/UnderConstruction'
import OurGroupVimal from './pages/OurGroupVimal'
import ContactUs from './pages/ContactUs'
import OurGroupDeep from './pages/OurGroupDeep'
import OurGroupVD from './pages/OurGroupVD'

function App() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div className='app'>
            <Navbar />
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/vimal-infracon' element={<OurGroupVimal />} />
                <Route path='/deep-infra' element={<OurGroupDeep />} />
                <Route path='/vd-zincare' element={<OurGroupVD />} />
                <Route path='/contact-us' element={<ContactUs />} />
            </Routes>
            <Footer />
        </div>
    )
}

export default App
