import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'

import nameLogo from '../assets/images/vimal-name-logo.png'
import vimalGroupIcon from '../assets/images/vimal-group-icon.png'
import mobileMenu from '../assets/images/mobile-menu.png'
import { arrowDownSVG, arrowUpSVG } from '../assets/svgImages'

export default function Header() {

    const [isDropdownOpen, setDropdown] = useState(false)
    const [isMobileDropdownOpen, setMobileDropdown] = useState(false)

    return (<header>
        <div className='logo'>
            <NavLink to='/'>
                <img className='vimal-group-icon' src={vimalGroupIcon} alt='Icon' />
                <img className='vimal-group-name' src={nameLogo} alt='Logo' />
            </NavLink>
        </div>
        <nav>
            <div className='line'></div>
            <ul className='desktop-menu'>
                <li><NavLink to='/'>Home</NavLink></li>
                <li>
                    <button onClick={() => setDropdown(!isDropdownOpen)}>
                        Our Group <span dangerouslySetInnerHTML={{ __html: isDropdownOpen ? arrowUpSVG : arrowDownSVG }} />
                    </button>
                    {isDropdownOpen && (
                        <ul className='drop-down'>
                            <li className='drop-down-items' onClick={() => setDropdown(false)}><NavLink to='/vimal-infracon'>Vimal Infracon</NavLink></li>
                            <li className='drop-down-items' onClick={() => setDropdown(false)}><NavLink to='/deep-infra'>Deep Infra</NavLink></li>
                            <li className='drop-down-items' onClick={() => setDropdown(false)}><NavLink to='/vd-zincare'>VD Zincare</NavLink></li>
                        </ul>
                    )}
                </li>
                <li><NavLink to='/contact-us'>Contact Us</NavLink></li>
            </ul>
            <div className='mobile-menu'>
                <button className='icon' onClick={() => setMobileDropdown(!isMobileDropdownOpen)}>
                    <img src={mobileMenu} alt="" />
                </button>
                {isMobileDropdownOpen && (
                    <ul className='drop-down' style={{ width: '180px', left: '-140px', top: '40px' }}>
                        <li className='drop-down-items' onClick={() => setMobileDropdown(false)}><NavLink to='/'>Home</NavLink></li>
                        <li className='drop-down-items' onClick={() => setMobileDropdown(false)}><NavLink to='/contact-us'>Contact Us</NavLink></li>
                        <li className='drop-down-items' onClick={() => setMobileDropdown(false)}><NavLink to='/vimal-infracon'>Vimal Infracon</NavLink></li>
                        <li className='drop-down-items' onClick={() => setMobileDropdown(false)}><NavLink to='/deep-infra'>Deep Infra</NavLink></li>
                        <li className='drop-down-items' onClick={() => setMobileDropdown(false)}><NavLink to='/vd-zincare'>vd-zincare</NavLink></li>
                    </ul>
                )}
            </div>
        </nav>
    </header>)

}
