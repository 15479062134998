import React from 'react'
import './home.css'

import nameLogo from '../assets/images/vimal-group-logo-white.png'
import aboutUsImg from '../assets/images/about-us.png'
import vimalLogo from '../assets/images/vimal-logo.png'
import deepLogo from '../assets/images/deep-logo.jpg'
import VDLogo from '../assets/images/VD-logo.png'
import ImgClient1 from '../assets/images/home_page/client1.jpg'
import ImgClient2 from '../assets/images/home_page/client2.jpg'
import ImgClient3 from '../assets/images/home_page/client3.png'
import ImgClient4 from '../assets/images/home_page/client4.jpg'
import ImgClient5 from '../assets/images/home_page/client5.JPG'
import ImgClient6 from '../assets/images/home_page/client6.png'
import ImgClient7 from '../assets/images/home_page/client7.png'
import { NavLink } from 'react-router-dom'

function HomePage() {

    return (<div id='home'>
        <div className='hero-section'>
            <div className='blur-back-dark'>
                <div className='container'>
                    <div className='hero-wrapper'>
                        <div>
                            <img className='logo-image' src={nameLogo} alt='Logo' />
                            <p>
                                We turn your vision into reality.
                                From concept to completion,
                                our experienced team provides exceptional construction services to bring your projects to life,
                                on time and on budget.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='about-us'>
            <div className='line'></div>
            <div className='container'>
                <div className='about-us-wrapper'>
                    <div className='title-wrapper'>
                        <h2 className='title'>About Us</h2>
                        <h4 className='sub-title'>We Build For Your Comfort</h4>
                        <p className='description'>At <span className='company-name'>Vimal</span>, we&apos;re more than builders; we&apos;re architects of progress. With a legacy of craftsmanship and innovation, we construct spaces that inspire, connect, and endure. Discover how we&apos;re shaping the future of construction.</p>
                        <button className='button-primary'>More</button>
                    </div>
                    <div className='images'>
                        <img src={aboutUsImg} alt='about-us' />
                    </div>
                </div>
            </div>
        </div>
        <div className='company-list'>
            <div className='container'>
                < div className='company-list-wrapper'>
                    <div className='title-wrapper'>
                        <h2 className='title'>Welcome To Our</h2>
                        <h4 className='sub-title'>Companies</h4>
                    </div>
                    <div className='company-box-wrapper'>
                        <div className='company-box'>
                            <div className='logo-wrapper'>
                                <img src={vimalLogo} alt='vimal infracon img' />
                            </div>
                            <div className='details'>
                                <NavLink to='/vimal-infracon' className='company-name'>Vimal Infracon</NavLink>
                                <p>Highway Builders Inc. specializes in constructing and maintaining highways and bridges. With over 20 years of experience, they are known for their high-quality workmanship and commitment to safety. Their projects span across several states, ensuring smooth and durable roadways for all travelers.</p>
                            </div>
                        </div>
                        <div className='company-box'>
                            <div className='logo-wrapper'>
                                <img src={deepLogo} alt='deep infracon img' />
                            </div>
                            <div className='details'>
                                <NavLink to='/deep-infra' className='company-name'>Deep Infra</NavLink>
                                <p>Highway Builders Inc. specializes in constructing and maintaining highways and bridges. With over 20 years of experience, they are known for their high-quality workmanship and commitment to safety. Their projects span across several states, ensuring smooth and durable roadways for all travelers.</p>
                            </div>
                        </div>
                        <div className='company-box'>
                            <div className='logo-wrapper'>
                                <img src={VDLogo} alt='vd-zincare img' />
                            </div>
                            <div className='details'>
                                <NavLink to='/vd-zincare' className='company-name'>VD Zincare</NavLink>
                                <p>Highway Builders Inc. specializes in constructing and maintaining highways and bridges. With over 20 years of experience, they are known for their high-quality workmanship and commitment to safety. Their projects span across several states, ensuring smooth and durable roadways for all travelers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='our-clients'>
            <div className='container'>
                <div className='our-clients-wrapper'>
                    <div className='title-wrapper'>
                        <h2 className='title'>Our</h2>
                        <h4 className='sub-title'> Clients</h4>
                    </div>
                    <div className='client-list'>
                        <a href='https://ahmedabadcity.gov.in/' target='_blank' rel="noreferrer">
                            <img src={ImgClient1} alt='AMC' title='Amdavad Municipal Corporation' />
                        </a>
                        <a href='https://auda.org.in/' target='_blank' rel="noreferrer">
                            <img src={ImgClient2} alt='AUDA' title='Ahmedabad Urban Development Authority' />
                        </a>
                        <a href='http://www.esselgroup.com/' target='_blank' rel="noreferrer">
                            <img src={ImgClient3} alt='Essel' title='ESSEL Group' />
                        </a>
                        <a href='https://gujaratindia.gov.in/' target='_blank' rel="noreferrer">
                            <img src={ImgClient4} alt='Gov of Gujarat' title='Goverment Of Gujarat' />
                        </a>
                        <a href='https://www.gujaratmetrorail.com/ahmedabad/' target='_blank' rel="noreferrer">
                            <img src={ImgClient5} alt='Ahmedabad Metro' title='Ahmedabad Metro Rail Project' />
                        </a>
                        <a href='https://parivahan.gov.in/parivahan/en/content/morth' target='_blank' rel="noreferrer">
                            <img src={ImgClient6} alt='MORTH' title='Ministry of Road Transport & Highways' />
                        </a>
                        <a href='https://nhai.gov.in/' target='_blank' rel="noreferrer">
                            <img src={ImgClient7} alt='NHAI' title='National Highways Authority of India' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default HomePage
