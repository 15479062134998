import React from 'react'
import { NavLink } from 'react-router-dom'
import './footer.css'

import nameLogo from '../assets/images/vimal-white-logo.png'
import { mapPin, phoneCall } from '../assets/svgImages'

export default function Footer() {

    return (<footer>
        <div className='footer-top'>
            <div style={{ backgroundColor: '#333333cc' }}>
                <div className='container'>
                    <div className='footer-top-wrapper'>
                        <div className='company-info'>
                            <NavLink to='/'>
                                <img src={nameLogo} alt='Logo' />
                            </NavLink>
                            <p>We specialize in road construction and infrastructure, delivering durable, high-quality solutions that connect communities and support growth.</p>
                        </div>
                        <div className='main-menu'>
                            <h4>Main Menu</h4>
                            <h5><NavLink to='/'>Home</NavLink></h5>
                            <h5><NavLink to='/contact-us'>Contact Us</NavLink></h5>
                        </div>
                        <div className='our-group'>
                            <h4>Our Group</h4>
                            <h5><NavLink to='/vimal-infracon'>Vimal Infracon</NavLink></h5>
                            <h5><NavLink to='/deep-infra'>Deep Infra</NavLink></h5>
                            <h5><NavLink to='/vd-zincare'>VD Zincare</NavLink></h5>
                        </div>
                        <div className='contact-us'>
                            <h4>Contact Us</h4>
                            <h5>
                                <span style={{ paddingRight: '5px' }} dangerouslySetInnerHTML={{ __html: mapPin }} />
                                <a target='_blank' href='https://maps.app.goo.gl/yBzHztk2c4NvZuBUA' rel="noreferrer">
                                    506-509, Vishala Supreme,
                                    opp. Torrent Power House,
                                    Nikol, Ahmedabad,
                                    Gujrat - 352350
                                </a>
                            </h5>
                            <h5>
                                <span style={{ paddingRight: '5px' }} dangerouslySetInnerHTML={{ __html: phoneCall }} />
                                <span>+91 98765 43210</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='copyright-footer'></div>
    </footer>)
}
